import { IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { AttachedTemplateInterface } from '@src/interfaces/documentsTemplates'
import { DocumentStatuses } from '@src/interfaces/documents'

export type AssigneeType = 'relationship' | 'employee' | 'group'

export enum OnboardingTimelineStatus {
  not_started = 'not_started',
  in_progress = 'in_progress',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum OnboardingTimelineTemplateStatus {
  pending_request = 'pending_request',
  pending_upload = 'pending_upload',
  pending_approval = 'pending_approval',
  pending_signature = 'pending_signature',
  rejected = 'rejected',
  completed = 'completed',
}

export interface OnboardingTimelineInterface {
  id: number
  cancel_reason: string | null
  employee: EmployeeOptionInterface
  hr_manager: EmployeeOptionInterface
  status: IdAndName<OnboardingTimelineStatus>
  creation_date_time: string
  update_date_time: string
}

export interface OnboardingTimelineUploadedDocumentInterface {
  id: number
  name: string
  status: IdAndName<OnboardingTimelineTemplateStatus>
}

interface DocumentTemplateInterface extends AttachedTemplateInterface {
  status: IdAndName<DocumentStatuses>
}

export interface TaskDefinitionInterface {
  id: number
  name: string
  status: OnboardingTimelineStatus
  description?: string
  default_assignee: IdAndName | null
  default_assignee_group: IdAndName | null
  default_assignee_relationship: IdAndName | null
  default_assignee_type: IdAndName<AssigneeType>
  assignee: IdAndName
}

export interface OnboardingTimelineTemplateInterface {
  id: number
  created_by: EmployeeOptionInterface
  name: string
  status: IdAndName<OnboardingTimelineTemplateStatus>
  document_template: DocumentTemplateInterface
  uploaded_document: OnboardingTimelineUploadedDocumentInterface | null
  docusign_envelope_id?: string
  creation_date_time: string
  update_date_time: string
}
