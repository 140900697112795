import React from 'react'
import { Avatar, IconButton, Item, Token } from '@revolut/ui-kit'
import { TaskDefinitionInterface } from '@src/interfaces/onboardingV2'

type Props = {
  handleClick?: () => void
  handleDelete?: () => void
  task: TaskDefinitionInterface
}

export const TaskItem = ({ handleClick, handleDelete, task }: Props) => {
  return (
    <Item onClick={handleClick} use={handleClick ? 'button' : undefined}>
      <Item.Avatar>
        <Avatar
          bg={Token.color.white}
          color={Token.color.greyTone20}
          useIcon="RadiobuttonOff"
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{task.name}</Item.Title>
        <Item.Description>{`Assigned to ${task.assignee.name}`}</Item.Description>
      </Item.Content>
      <Item.Side>
        {handleDelete && (
          <IconButton
            aria-label={`Delete task ${task.name}`}
            color={Token.color.greyTone20}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleDelete()
            }}
            useIcon="Delete"
          />
        )}
      </Item.Side>
    </Item>
  )
}
