export const employeeOnbNotStarted = {
  id: 7,
  status: {
    id: 'not_started',
    name: 'Not started',
  },
  cancel_reason: null,
  hr_manager: {
    id: 12354117,
    full_name: 'Thomas Dobereiner',
    display_name: 'Thomas Dobereiner',
    name: 'Thomas Dobereiner',
    status: 'active',
    avatar:
      'https://storage.googleapis.com/revoluters-dev-public-files/logo_HsVEAvu.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=documents-manager%40revolut-dev-peopleops.iam.gserviceaccount.com%2F20240529%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240529T130001Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=a9535ab2610bf765d292cba7994a2a122f647d5bf0297c40c73416192318f5d98e90364d0c07a55782ae361d71bd1b45a8d7dc4cf85bc7b583dec33ae24bc828960cb9dd28c889f2735f53cd174234ac2628205ea5574a0188828015b8a2bc51894a5e0301e85622b9b106fdfbcca7c375cffb7bfb885b6463c54698140338e51264fa26831822cc06b495e38117d7ca989215bf1c91dce7355fa142494b8669e32b1a01b0e6a13b2e436a62081e6db4b871f06cb991a28a9fe0997769c4e5f9ff67789e64c854d8ec2907f13d9900429436f33d9fb11a2d1eb51062f428e7da1879f6a15f1571118fefbb6d4d2a53839fd9ac67710b15a470de376202c68011',
    employee_type: 'internal',
  },
  employee: {
    id: 1234,
    full_name: 'Peter Snow',
    display_name: 'Peter Snow',
    name: 'John Snow',
    status: {
      id: 'active',
      name: 'Active',
    },
    avatar:
      'https://avatars.slack-edge.com/2023-02-07/4755069965990_75446fca3cdb3a51a083_32.png',
    employee_type: 'internal',
  },
  creation_date_time: '2023-10-09T12:25:58.957269Z',
  update_date_time: '2023-10-09T12:25:58.957269Z',
}

export const employeeOnbStarted = {
  id: 8,
  status: {
    id: 'in_progress',
    name: 'In Progress',
  },
  cancel_reason: null,
  hr_manager: {
    id: 12354117,
    full_name: 'Thomas Dobereiner',
    display_name: 'Thomas Dobereiner',
    name: 'Thomas Dobereiner',
    avatar:
      'https://storage.googleapis.com/revoluters-dev-public-files/logo_HsVEAvu.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=documents-manager%40revolut-dev-peopleops.iam.gserviceaccount.com%2F20240529%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240529T130001Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=a9535ab2610bf765d292cba7994a2a122f647d5bf0297c40c73416192318f5d98e90364d0c07a55782ae361d71bd1b45a8d7dc4cf85bc7b583dec33ae24bc828960cb9dd28c889f2735f53cd174234ac2628205ea5574a0188828015b8a2bc51894a5e0301e85622b9b106fdfbcca7c375cffb7bfb885b6463c54698140338e51264fa26831822cc06b495e38117d7ca989215bf1c91dce7355fa142494b8669e32b1a01b0e6a13b2e436a62081e6db4b871f06cb991a28a9fe0997769c4e5f9ff67789e64c854d8ec2907f13d9900429436f33d9fb11a2d1eb51062f428e7da1879f6a15f1571118fefbb6d4d2a53839fd9ac67710b15a470de376202c68011',
  },
  employee: {
    id: 1234,
    full_name: 'Peter Snow',
    display_name: 'Peter Snow',
    name: 'John Snow',
    avatar:
      'https://avatars.slack-edge.com/2023-02-07/4755069965990_75446fca3cdb3a51a083_32.png',
  },
  creation_date_time: '2023-10-09T12:25:58.957269Z',
  update_date_time: '2023-10-09T12:25:58.957269Z',
}

export const documentsUpload = [
  {
    id: 123,
    status: {
      id: 'pending_request',
      name: 'Pending request',
    },
    name: 'Passport',
    uploaded_document: null,
    document_template: {
      id: 2343,
      name: 'Passport template',
      category: 'Ids',
      template_type: {
        id: 'request',
        name: 'Request',
      },
    },
    created_by: {
      id: 1234,
      full_name: 'Peter Snow',
      display_name: 'Peter Snow',
      name: 'John Snow',
      status: 'active',
      avatar:
        'https://avatars.slack-edge.com/2023-02-07/4755069965990_75446fca3cdb3a51a083_32.png',
      employee_type: 'internal',
    },
    creation_date_time: '2023-10-09T12:25:58.957269Z',
    update_date_time: '2023-10-09T12:25:58.957269Z',
  },
]

export const documentsUploadPending = [
  {
    id: 124,
    status: {
      id: 'pending_approval',
      name: 'Pending approval',
    },
    name: 'Passport',
    uploaded_document: {
      id: 3062,
      name: 'Password',
      category: 'IDs',
      status: {
        id: 'pending_approval',
        name: 'Pending approval',
      },
      approval_status: 'pending',
    },
    document_template: {
      id: 2343,
      name: 'Passport template',
      category: 'Ids',
      template_type: {
        id: 'request',
        name: 'Request',
      },
    },
    created_by: {
      id: 1234,
      full_name: 'Peter Snow',
      display_name: 'Peter Snow',
      name: 'John Snow',
      status: 'active',
      avatar:
        'https://avatars.slack-edge.com/2023-02-07/4755069965990_75446fca3cdb3a51a083_32.png',
      employee_type: 'internal',
    },
    creation_date_time: '2023-10-09T12:25:58.957269Z',
    update_date_time: '2023-10-09T12:25:58.957269Z',
  },
]

export const documentsSignature = [
  {
    id: 124,
    status: {
      id: 'pending_request',
      name: 'Pending request',
    },
    name: 'Offer letter',
    uploaded_document: null,
    document_template: {
      id: 191,
      name: 'Offer letter',
      template_type: {
        id: 'esignature',
        name: 'eSignature',
      },
    },
    created_by: {
      id: 12354117,
      full_name: 'Thomas Dobereiner',
      display_name: 'Thomas Dobereiner',
      name: 'Thomas Dobereiner',
      status: 'active',
      avatar:
        'https://storage.googleapis.com/revoluters-dev-public-files/logo_HsVEAvu.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=documents-manager%40revolut-dev-peopleops.iam.gserviceaccount.com%2F20240529%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240529T130001Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=a9535ab2610bf765d292cba7994a2a122f647d5bf0297c40c73416192318f5d98e90364d0c07a55782ae361d71bd1b45a8d7dc4cf85bc7b583dec33ae24bc828960cb9dd28c889f2735f53cd174234ac2628205ea5574a0188828015b8a2bc51894a5e0301e85622b9b106fdfbcca7c375cffb7bfb885b6463c54698140338e51264fa26831822cc06b495e38117d7ca989215bf1c91dce7355fa142494b8669e32b1a01b0e6a13b2e436a62081e6db4b871f06cb991a28a9fe0997769c4e5f9ff67789e64c854d8ec2907f13d9900429436f33d9fb11a2d1eb51062f428e7da1879f6a15f1571118fefbb6d4d2a53839fd9ac67710b15a470de376202c68011',
      employee_type: 'internal',
    },
    creation_date_time: '2023-10-09T12:25:58.957269Z',
    update_date_time: '2023-10-09T12:25:58.957269Z',
    docusign_envelope_id: '6bf7ae4a-68c3-4b12-88a1-a0e6e30ffdda',
  },
]

export const documentsSignaturePending = [
  {
    id: 124,
    status: {
      id: 'pending_request',
      name: 'Pending request',
    },
    name: 'Offer letter',
    uploaded_document: {
      id: 51582,
      name: 'Password',
      category: 'IDs',
      status: {
        id: 'pending_signature',
        name: 'Pending signature',
      },
      approval_status: 'pending',
    },
    document_template: {
      id: 191,
      name: 'Offer letter',
      category: 'IDs',
      template_type: {
        id: 'esignature',
        name: 'eSignature',
      },
    },
    created_by: {
      id: 12354117,
      full_name: 'Thomas Dobereiner',
      display_name: 'Thomas Dobereiner',
      name: 'Thomas Dobereiner',
      status: 'active',
      avatar:
        'https://storage.googleapis.com/revoluters-dev-public-files/logo_HsVEAvu.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=documents-manager%40revolut-dev-peopleops.iam.gserviceaccount.com%2F20240529%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240529T130001Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=a9535ab2610bf765d292cba7994a2a122f647d5bf0297c40c73416192318f5d98e90364d0c07a55782ae361d71bd1b45a8d7dc4cf85bc7b583dec33ae24bc828960cb9dd28c889f2735f53cd174234ac2628205ea5574a0188828015b8a2bc51894a5e0301e85622b9b106fdfbcca7c375cffb7bfb885b6463c54698140338e51264fa26831822cc06b495e38117d7ca989215bf1c91dce7355fa142494b8669e32b1a01b0e6a13b2e436a62081e6db4b871f06cb991a28a9fe0997769c4e5f9ff67789e64c854d8ec2907f13d9900429436f33d9fb11a2d1eb51062f428e7da1879f6a15f1571118fefbb6d4d2a53839fd9ac67710b15a470de376202c68011',
      employee_type: 'internal',
    },
    creation_date_time: '2023-10-09T12:25:58.957269Z',
    update_date_time: '2023-10-09T12:25:58.957269Z',
    docusign_envelope_id: '6bf7ae4a-68c3-4b12-88a1-a0e6e30ffdda',
  },
]

export const tasks = [
  {
    id: 1,
    name: 'prepare desktop',
    description: 'put monitor, keyboard, etc.',
    status: {
      id: 'not_started',
      name: 'Not started',
    },
    cancel_reason: null,
    employee_onboarding: {
      id: 123,
    },
    assignee: {
      id: 12354117,
      full_name: 'Thomas Dobereiner',
      display_name: 'Thomas Dobereiner',
      name: 'Thomas Dobereiner',
      status: 'active',
      avatar:
        'https://storage.googleapis.com/revoluters-dev-public-files/logo_HsVEAvu.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=documents-manager%40revolut-dev-peopleops.iam.gserviceaccount.com%2F20240529%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240529T130001Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=a9535ab2610bf765d292cba7994a2a122f647d5bf0297c40c73416192318f5d98e90364d0c07a55782ae361d71bd1b45a8d7dc4cf85bc7b583dec33ae24bc828960cb9dd28c889f2735f53cd174234ac2628205ea5574a0188828015b8a2bc51894a5e0301e85622b9b106fdfbcca7c375cffb7bfb885b6463c54698140338e51264fa26831822cc06b495e38117d7ca989215bf1c91dce7355fa142494b8669e32b1a01b0e6a13b2e436a62081e6db4b871f06cb991a28a9fe0997769c4e5f9ff67789e64c854d8ec2907f13d9900429436f33d9fb11a2d1eb51062f428e7da1879f6a15f1571118fefbb6d4d2a53839fd9ac67710b15a470de376202c68011',
      employee_type: 'internal',
    },
    creation_date_time: '2024-05-22T20:06:03.550927Z',
    update_date_time: '2024-05-22T20:06:03.550942Z',
  },
]
