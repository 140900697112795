import { api } from '.'
import { RequestInterfaceNew } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  OnboardingTimelineInterface,
  OnboardingTimelineTemplateInterface,
  TaskDefinitionInterface,
} from '@src/interfaces/onboardingV2'
import { useFetch } from '@src/utils/reactQuery'
import {
  documentsSignaturePending,
  documentsUploadPending,
  employeeOnbStarted,
  tasks,
} from '@src/api/mocks/employeeOnb/mocks'

export const useGetOnboardingTimeline = (onboardingId?: string) => {
  const result = useFetch<OnboardingTimelineInterface>(
    onboardingId ? `/employeeOnboarding/employees/${onboardingId}` : null,
    'v2',
  )

  result.data = employeeOnbStarted as OnboardingTimelineInterface

  return result
}

export const cancelOnboarding = (onboardingId: number, cancel_reason?: string) =>
  api.post<OnboardingTimelineInterface>(
    `/employeeOnboarding/employees/${onboardingId}/cancel`,
    { cancel_reason },
    undefined,
    'v2',
  )

export const startOnboarding = (onboardingId: number) =>
  api.post<OnboardingTimelineInterface>(
    `/employeeOnboarding/employees/${onboardingId}/start`,
    undefined,
    undefined,
    'v2',
  )

export const transferOnboarding = (
  onboardingId: number,
  hr_manager: EmployeeOptionInterface,
) =>
  api.post<OnboardingTimelineInterface>(
    `/employeeOnboarding/employees/${onboardingId}/transfer`,
    { hr_manager },
    undefined,
    'v2',
  )

export const useGetDocumentUploadRequests = (onboardingId?: string) => {
  const result = useFetch<OnboardingTimelineTemplateInterface[]>(
    onboardingId
      ? `/employeeOnboarding/employees/${onboardingId}/documentUploadRequests`
      : null,
    'v2',
  )

  result.data = documentsUploadPending as any

  return result
}

// TODO fix types
export const addDocumentUpload = (onboardingId: string, data: any) =>
  api.post(
    `/employeeOnboarding/employees/${onboardingId}/documentUploadRequests`,
    data,
    undefined,
    'v2',
  )

export const useGetDocumentSignatureRequests = (onboardingId?: string) => {
  const result = useFetch<OnboardingTimelineTemplateInterface[]>(
    onboardingId
      ? `/employeeOnboarding/employees/${onboardingId}/documentSignatureRequests`
      : null,
    'v2',
  )

  result.data = documentsSignaturePending as any

  return result
}

// TODO fix types
export const addDocumentSignature = (onboardingId: string, data: any) =>
  api.post(
    `/employeeOnboarding/employees/${onboardingId}/documentSignatureRequests`,
    data,
    undefined,
    'v2',
  )

export const useGetTasksRequests = (onboardingId?: string) => {
  const result = useFetch<TaskDefinitionInterface[]>(
    onboardingId ? `/employeeOnboarding/employees/${onboardingId}/tasks` : null,
    'v2',
  )

  result.data = tasks as any

  return result
}

export const employeeOnboardingTasksRequests: RequestInterfaceNew<TaskDefinitionInterface> =
  {
    get: async ({ onboardingId, id }) =>
      api.get(`/employeeOnboarding/employees/${onboardingId}/tasks/${id}`),
    update: async (data, { onboardingId, id }) => {
      return api.patch(`/employeeOnboarding/employees/${onboardingId}/tasks/${id}`, data)
    },
    submit: async (data, { onboardingId }) => {
      return api.post(`/employeeOnboarding/employees/${onboardingId}/tasks`, data)
    },
  }

export const sendSignatureRequest = (onboardingId: string, id: number) =>
  api.post(
    `/employeeOnboarding/employees/${onboardingId}/documentSignatureRequests/${id}/send`,
    undefined,
    undefined,
    'v2',
  )
