import React from 'react'
import { InputGroup, MoreBar, Side, Subheader } from '@revolut/ui-kit'
import { employeeOnboardingTasksRequests } from '@src/api/onboardingEmployeesV2'
import { selectorKeys } from '@src/constants/api'
import { TaskDefinitionInterface } from '@src/interfaces/onboardingV2'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import SideBar from '@src/components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'

interface TaskFormProps {
  onClose: () => void
}

const TaskForm = ({ onClose }: TaskFormProps) => {
  const { values } = useLapeContext<TaskDefinitionInterface>()
  return (
    <>
      <Side.Actions>
        {values.id && (
          <MoreBar>
            <MoreBar.Action useIcon="Check" variant="accent">
              Mark as done
            </MoreBar.Action>
            <MoreBar.Action useIcon="Bell">Trigger notification</MoreBar.Action>
            <MoreBar.Action useIcon="Delete" variant="negative">
              Delete
            </MoreBar.Action>
          </MoreBar>
        )}
      </Side.Actions>
      <InputGroup>
        <LapeNewInput label="Task title" name="name" />
        <LapeNewTextArea label="Task description" name="description" rows={3} />
        <Subheader>
          <Subheader.Title>Who should be responsible for this task?</Subheader.Title>
        </Subheader>
        <LapeRadioSelectInput
          label="Assignee type"
          name="default_assignee_type"
          selector={selectorKeys.approval_step_approver_types}
        />
        {values.default_assignee_type?.id === 'employee' && (
          <LapeRadioSelectInput
            label="Assignee"
            name="default_assignee"
            selector={selectorKeys.employee}
          />
        )}
        {values.default_assignee_type?.id === 'group' && (
          <LapeRadioSelectInput
            label="Group"
            name="default_assignee_group"
            selector={selectorKeys.dynamic_groups}
          />
        )}
        {values.default_assignee_type?.id === 'relationship' && (
          <LapeRadioSelectInput
            label="Relationship"
            name="default_assignee_relationship"
            selector={selectorKeys.employee_onboarding_default_assignee_relationships}
          />
        )}
      </InputGroup>

      <Side.Actions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => onClose()}
          successText={
            values?.id ? 'Task was successfully updated' : 'Task was successfully created'
          }
          useValidator
        >
          {values?.id ? 'Update task' : 'Save task'}
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
}

export const TaskSidebar = ({
  isOpen,
  onClose,
  onboardingId,
  taskId,
}: {
  isOpen: boolean
  onClose: () => void
  onboardingId: string
  taskId?: number
}) => {
  return (
    <SideBar isOpen={isOpen} onClose={onClose} title="Add task">
      <Form
        api={employeeOnboardingTasksRequests}
        forceParams={{
          onboardingId,
          new: taskId ? undefined : 'new',
          id: String(taskId),
        }}
        disableLocalStorageCaching
      >
        <TaskForm onClose={onClose} />
      </Form>
    </SideBar>
  )
}
