import React from 'react'
import { HStack, Skeleton, Tag, Text, Token, Icon } from '@revolut/ui-kit'
import {
  OnboardingTimelineInterface,
  OnboardingTimelineStatus,
} from '@src/interfaces/onboardingV2'
import { formatDateTime } from '@src/utils/format'

type OnboardingSubtitleProps = {
  loading?: boolean
  onboardingData?: OnboardingTimelineInterface
}

export const statusToColor = {
  [OnboardingTimelineStatus.not_started]: Token.color.greyTone50,
  [OnboardingTimelineStatus.in_progress]: Token.color.orange,
  [OnboardingTimelineStatus.completed]: Token.color.green,
  [OnboardingTimelineStatus.cancelled]: Token.color.red,
}

export const OnboardingSubtitle = ({
  loading,
  onboardingData,
}: OnboardingSubtitleProps) => {
  if (!onboardingData) {
    return null
  }

  return (
    <HStack align="center" gap="s-16">
      {loading ? (
        <>
          <Skeleton width={150} /> <Skeleton width={250} />
        </>
      ) : (
        <>
          <Tag color={statusToColor[onboardingData.status.id]} variant="outlined">
            {onboardingData.status.name}
          </Tag>
          <HStack align="center" gap="s-4">
            <Icon color={Token.color.greyTone50} name="Time" size={16} />
            <Text use="div" color={Token.color.greyTone50} variant="caption">
              {`Last activity at ${formatDateTime(onboardingData.update_date_time)}`}
            </Text>
          </HStack>
        </>
      )}
    </HStack>
  )
}
