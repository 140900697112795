import React from 'react'
import { Header, Token, Portal } from '@revolut/ui-kit'
import { Services } from '@revolut/icons'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface, InternalOrExternalEmployee } from '@src/interfaces/employees'
import { DynamicGroupIDs } from '@src/interfaces/customFields'
import { DataHandlerInterface } from '@components/FormPreview/FormPreview'
import SideBar from '@components/SideBar/SideBar'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import EditName from '@src/pages/EmployeeProfile/Forms/Name'
import EditPosition from '@src/pages/EmployeeProfile/Forms/Position'
import EditOrganisation from '@src/pages/EmployeeProfile/Forms/Organisation'
import { SidebarEditFormWrapper } from '@src/pages/EmployeeProfile/Layout/Summary/common'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { pathToUrl } from '@src/utils/router'

export type SidebarEditMode = 'name' | 'position' | 'organisation'

type Props = {
  data?: InternalOrExternalEmployee
  dynamicGroups?: DynamicGroupIDs
  editMode?: SidebarEditMode
  onClose: () => void
  primaryDataHandler: DataHandlerInterface<EmployeeInterface>
  sectionsData: ProfileSectionsData
}
export const EmployeeInformationSidebar = ({
  data = {} as EmployeeInterface,
  dynamicGroups = [],
  editMode,
  onClose,
  primaryDataHandler,
  sectionsData,
}: Props) => {
  return (
    <Portal>
      <SideBar
        avatarProps={{ color: Token.color.greyTone20 }}
        isOpen={!!editMode}
        onClose={onClose}
        sideProps={{ resizable: true }}
        subtitle={<UserWithAvatar id={data.id} full_name={data?.display_name} />}
        variant="wide"
        useIcon={Services}
        customHeader={
          <Header>
            <Header.BackButton onClick={onClose} />
            <Header.Title data-testid="sidebar-title">Edit details</Header.Title>
            <Header.Description>
              <UserWithAvatar id={data.id} full_name={data?.display_name} />
            </Header.Description>
          </Header>
        }
        zIndex={0}
      >
        {editMode === 'name' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NAME, { id: data.id })}
          >
            <EditName
              isSidebarContent
              data={data as EmployeeInterface}
              dynamicGroups={dynamicGroups}
              refreshData={() => {
                primaryDataHandler.refetch()
                sectionsData.name.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'position' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION, { id: data.id })}
          >
            <EditPosition
              isSidebarContent
              data={data as EmployeeInterface}
              dynamicGroups={dynamicGroups}
              refreshData={() => {
                primaryDataHandler.refetch()
                sectionsData.position.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'organisation' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION, { id: data.id })}
          >
            <EditOrganisation
              isSidebarContent
              data={data as EmployeeInterface}
              refreshData={() => {
                primaryDataHandler.refetch()
                sectionsData.organisation.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
      </SideBar>
    </Portal>
  )
}
