import React, { useState } from 'react'
import { Avatar, Item, Group, Flex, Text, Copyable, Token } from '@revolut/ui-kit'
import { ChevronRight } from '@revolut/icons'
import { DataHandlerInterface, FormPreview } from '@components/FormPreview/FormPreview'
import { EmployeeInterface, InternalOrExternalEmployee } from '@src/interfaces/employees'
import { useGetEmployeeSettings } from '@src/api/settings'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { onCopyFieldNotification } from '@src/pages/EmployeeProfile/Layout/common/helpers'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { isLoadingSectionData } from '@src/pages/EmployeeProfile/Layout/Summary/common'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { EmployeeInformationSidebar, SidebarEditMode } from './EmployeeInformationSidebar'

type Props = {
  data: EmployeeInterface
  primaryDataHandler: DataHandlerInterface<EmployeeInterface>
  sectionsData: ProfileSectionsData
}

export const EmployeeInformation = ({
  data,
  primaryDataHandler,
  sectionsData,
}: Props) => {
  const { data: employeeSettings } = useGetEmployeeSettings()
  const [editMode, setEditMode] = useState<SidebarEditMode>()

  const isInternal = data?.employee_type === 'internal'
  const basicDetailsSectionLoading = isLoadingSectionData(sectionsData.name.handler)
  const positionSectionLoading = isLoadingSectionData(sectionsData.position.handler)
  const organisationSectionLoading = isLoadingSectionData(
    sectionsData.organisation.handler,
  )

  return (
    <>
      <Group>
        <Item
          aria-label="Open basic details sidebar"
          onClick={() => setEditMode('name')}
          use="button"
        >
          <Item.Avatar>
            <Avatar useIcon="Profile" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Basic details</Item.Title>
          </Item.Content>
          <Item.Side>
            <ChevronRight color={Token.color.greyTone50} />
          </Item.Side>
        </Item>
        <FormPreview<Partial<InternalOrExternalEmployee>>
          data={sectionsData.name.data}
          dataHandler={sectionsData.name.handler}
          // hideEdit={() => !sectionsData.name.permissions?.canEdit}
        >
          <Group>
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="first_name"
              loading={basicDetailsSectionLoading}
              title="Legal first name"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="middle_name"
              loading={basicDetailsSectionLoading}
              title="Legal middle name"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="last_name"
              loading={basicDetailsSectionLoading}
              title="Legal last name"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="email"
              insert={d =>
                d.email ? (
                  <Copyable
                    value={d.email}
                    labelButtonCopy="Copy employee email"
                    onClick={() => onCopyFieldNotification('Email')}
                  >
                    {d.email}
                  </Copyable>
                ) : (
                  '-'
                )
              }
              loading={basicDetailsSectionLoading}
              title="Work email"
            />
          </Group>
        </FormPreview>
      </Group>

      <Group>
        <Item
          aria-label="Open contract details sidebar"
          onClick={() => setEditMode('position')}
          use="button"
        >
          <Item.Avatar>
            <Avatar useIcon="Services" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Contract details</Item.Title>
          </Item.Content>
          <Item.Side>
            <ChevronRight color={Token.color.greyTone50} />
          </Item.Side>
        </Item>
        <FormPreview<Partial<InternalOrExternalEmployee>>
          data={sectionsData.position.data}
          dataHandler={sectionsData.position.handler}
          // hideEdit={() => !sectionsData.position.permissions?.canEdit}
        >
          <Group>
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="contract_type.name"
              loading={positionSectionLoading}
              title="Contract type"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="specialisation.name"
              to={d =>
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                  id: d.specialisation?.id,
                })
              }
              loading={positionSectionLoading}
              title="Role (Specialisation)"
            />
            <FormPreview.Item<EmployeeInterface>
              hidden={!isInternal}
              insert={d => (
                <Flex>
                  <Text>{d.seniority?.name || '-'}</Text>
                  {d.specialisation_seniority_sublevel && (
                    <Text pl="s-6">({d.specialisation_seniority_sublevel.name})</Text>
                  )}
                </Flex>
              )}
              loading={positionSectionLoading}
              title="Seniority"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="job_title"
              hidden={!isInternal || !employeeSettings?.enable_job_title}
              loading={positionSectionLoading}
              title="Job title"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="location.name"
              loading={positionSectionLoading}
              title="Location"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="joining_date_time"
              loading={positionSectionLoading}
              title="Start date"
              type="date"
            />
          </Group>
        </FormPreview>
      </Group>

      <Group>
        <Item
          aria-label="Open Organisation details sidebar"
          onClick={() => setEditMode('organisation')}
          use="button"
        >
          <Item.Avatar>
            <Avatar useIcon="Services" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Organisation details</Item.Title>
          </Item.Content>
          <Item.Side>
            <ChevronRight color={Token.color.greyTone50} />
          </Item.Side>
        </Item>
        <FormPreview<Partial<InternalOrExternalEmployee>>
          data={sectionsData.organisation.data}
          dataHandler={sectionsData.organisation.handler}
          // hideEdit={() => !sectionsData.organisation.permissions?.canEdit}
        >
          <Group>
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="team.name"
              to={d => pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: d.team?.id })}
              loading={organisationSectionLoading}
              title="Team"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="line_manager.name"
              insert={d =>
                d.line_manager?.id ? (
                  <EmployeeUserWithAvatar
                    id={d.line_manager.id}
                    full_name={d.line_manager.display_name}
                  />
                ) : (
                  '-'
                )
              }
              loading={organisationSectionLoading}
              title={isInternal ? 'Line manager' : 'Point of contact 1'}
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="quality_control.name"
              insert={d =>
                d.quality_control?.id ? (
                  <EmployeeUserWithAvatar
                    id={d.quality_control.id}
                    full_name={d.quality_control.display_name}
                  />
                ) : (
                  '-'
                )
              }
              loading={organisationSectionLoading}
              title={isInternal ? 'Functional manager' : 'Point of contact 2'}
            />
            <FormPreview.Item<EmployeeInterface>
              field="mentor.name"
              hidden={!isInternal || !employeeSettings?.enable_buddy}
              insert={d =>
                d.mentor?.id ? (
                  <EmployeeUserWithAvatar
                    id={d.mentor.id}
                    full_name={d.mentor.display_name}
                  />
                ) : (
                  '-'
                )
              }
              loading={organisationSectionLoading}
              title="Buddy"
            />
          </Group>
        </FormPreview>
      </Group>

      <EmployeeInformationSidebar
        data={data}
        editMode={editMode}
        onClose={() => setEditMode(undefined)}
        primaryDataHandler={primaryDataHandler}
        sectionsData={sectionsData}
      />
    </>
  )
}
