import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Cell, MoreBar, Subheader, Token, VStack, Text } from '@revolut/ui-kit'
import {
  addDocumentSignature,
  addDocumentUpload,
  useGetDocumentSignatureRequests,
  useGetDocumentUploadRequests,
  useGetTasksRequests,
} from '@src/api/onboardingEmployeesV2'
import { OnboardingTimelineTemplateInterface } from '@src/interfaces/onboardingV2'
import { SelectTemplatesSidebar } from '@src/apps/People/Documents/BulkRequest/Edit/components/SelectTemplatesSidebar'
import { ScreeningCheck } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/ScreeningCheck'
import { RightToWork } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/RightToWork'
import { Contracts } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/Contracts'
import { PreviewSidebar } from './PreviewSidebar'
import { TaskItem } from './TaskItem'
import { TemplateItem } from './TemplateItem'
import { TaskSidebar } from './TaskSidebar'

export const OnboardingInformation = () => {
  const { id, onboardingId } = useParams<{ id: string; onboardingId: string }>()
  const [taskId, setTaskId] = useState<number>()
  const [taskSideBarOpen, setTaskSiderOpen] = useState(false)
  const [templatesSidebar, setTemplatesSidebar] = useState<'esignature' | 'request'>()
  const [templatePreview, setTemplatePreview] =
    useState<OnboardingTimelineTemplateInterface>()

  const { data: documentUploadData, refetch: refetchDocumentUploadData } =
    useGetDocumentUploadRequests(onboardingId)
  const { data: documentSignatureData, refetch: refetchDocumentSignatureData } =
    useGetDocumentSignatureRequests(onboardingId)
  const { data: tasksData, refetch: refetchTasksData } = useGetTasksRequests(onboardingId)

  const attachedTemplates =
    (templatesSidebar === 'request' ? documentUploadData : documentSignatureData)?.map(
      template => ({
        id: template.document_template.id,
        name: template.document_template.name,
      }),
    ) || []

  const handleDocumentUpload = async (newTemplate: any) => {
    try {
      await addDocumentUpload(onboardingId, newTemplate)
    } finally {
      refetchDocumentUploadData()
    }
  }

  const handleDocumentSignature = async (newTemplate: any) => {
    try {
      await addDocumentSignature(onboardingId, newTemplate)
    } finally {
      refetchDocumentSignatureData()
    }
  }

  const handleAddTemplate = (newTemplate: any) => {
    templatesSidebar === 'request'
      ? handleDocumentUpload(newTemplate)
      : handleDocumentSignature(newTemplate)
  }

  return (
    <>
      <VStack gap="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Documents for the employee to upload</Subheader.Title>
        </Subheader>
        <Cell>
          <VStack width="100%" space="s-16">
            {!!documentUploadData?.length && (
              <VStack>
                {documentUploadData.map(documentUpload => (
                  <TemplateItem
                    key={documentUpload.id}
                    template={documentUpload}
                    handleClick={() => setTemplatePreview(documentUpload)}
                  />
                ))}
              </VStack>
            )}
            {!documentUploadData?.length && (
              <Text color={Token.color.greyTone50} variant="h6">
                Nothing requested yet
              </Text>
            )}
            <MoreBar>
              <MoreBar.Action
                onClick={() => setTemplatesSidebar('request')}
                useIcon="Plus"
              >
                Request new document
              </MoreBar.Action>
            </MoreBar>
          </VStack>
        </Cell>

        <Subheader variant="nested">
          <Subheader.Title>Documents for the employee to upload</Subheader.Title>
        </Subheader>
        <Cell>
          <VStack width="100%" space="s-16">
            {!!documentSignatureData?.length && (
              <VStack>
                {documentSignatureData.map(documentSignature => (
                  <TemplateItem
                    key={documentSignature.id}
                    template={documentSignature}
                    handleClick={() => setTemplatePreview(documentSignature)}
                  />
                ))}
              </VStack>
            )}
            {!documentSignatureData?.length && (
              <Text color={Token.color.greyTone50} variant="h6">
                Nothing requested yet
              </Text>
            )}
            <MoreBar>
              <MoreBar.Action
                onClick={() => setTemplatesSidebar('esignature')}
                useIcon="Plus"
              >
                Request new document
              </MoreBar.Action>
            </MoreBar>
          </VStack>
        </Cell>

        <Subheader variant="nested">
          <Subheader.Title>Tasks</Subheader.Title>
        </Subheader>
        <Cell>
          <VStack width="100%" space="s-16">
            {!!tasksData?.length && (
              <VStack>
                {tasksData.map(task => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    handleClick={() => {
                      setTaskSiderOpen(true)
                      setTaskId(task.id)
                    }}
                  />
                ))}
              </VStack>
            )}
            {!tasksData?.length && (
              <Text color={Token.color.greyTone50} variant="h6">
                Nothing added yet
              </Text>
            )}
            <MoreBar>
              <MoreBar.Action onClick={() => setTaskSiderOpen(true)} useIcon="Plus">
                Add task
              </MoreBar.Action>
            </MoreBar>
          </VStack>
        </Cell>

        <ScreeningCheck employeeId={Number(id)} hideTableRoute />

        <RightToWork employeeId={Number(id)} hideTableRoute />

        <Contracts employeeId={Number(id)} hideTableRoute />
      </VStack>

      {!!templatesSidebar && (
        <SelectTemplatesSidebar
          allowCreate={templatesSidebar === 'request'}
          attachedTemplates={attachedTemplates}
          filterByTypes={[templatesSidebar]}
          isOpen={!!templatesSidebar}
          onAddTemplate={newTemplate => handleAddTemplate(newTemplate)}
          onClose={() => setTemplatesSidebar(undefined)}
        />
      )}

      <PreviewSidebar
        document={templatePreview}
        employeeId={id}
        onboardingId={onboardingId}
        onClose={() => setTemplatePreview(undefined)}
      />

      <TaskSidebar
        isOpen={taskSideBarOpen}
        onClose={() => {
          setTaskSiderOpen(false)
          setTaskId(undefined)
          refetchTasksData() // TODO
        }}
        onboardingId={onboardingId}
        taskId={taskId}
      />
    </>
  )
}
