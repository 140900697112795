import React from 'react'
import { Avatar, IconName, Item, Text, Token } from '@revolut/ui-kit'
import { DocumentsTemplateTypeId } from '@src/interfaces/documentsTemplates'
import {
  OnboardingTimelineTemplateStatus,
  OnboardingTimelineTemplateInterface,
} from '@src/interfaces/onboardingV2'
import { formatDateTime } from '@src/utils/format'

type Props = {
  handleClick: () => void
  template: OnboardingTimelineTemplateInterface
}

export const documentStatusToIcon: Record<OnboardingTimelineTemplateStatus, IconName> = {
  [OnboardingTimelineTemplateStatus.pending_approval]: 'Bell',
  [OnboardingTimelineTemplateStatus.pending_request]: '16/StatusClockArrows',
  [OnboardingTimelineTemplateStatus.pending_signature]: '16/StatusClockArrows',
  [OnboardingTimelineTemplateStatus.pending_upload]: '16/StatusClockArrows',
  [OnboardingTimelineTemplateStatus.completed]: 'Check',
  [OnboardingTimelineTemplateStatus.rejected]: 'Cross',
}

export const documentStatusToColor = {
  [OnboardingTimelineTemplateStatus.pending_approval]: Token.color.orange,
  [OnboardingTimelineTemplateStatus.pending_request]: Token.color.orange,
  [OnboardingTimelineTemplateStatus.pending_signature]: Token.color.orange,
  [OnboardingTimelineTemplateStatus.pending_upload]: Token.color.orange,
  [OnboardingTimelineTemplateStatus.completed]: Token.color.green,
  [OnboardingTimelineTemplateStatus.rejected]: Token.color.foreground,
}

const typeToIcon: Record<DocumentsTemplateTypeId, IconName> = {
  esignature: 'Pencil',
  generate: 'Lightning',
  request: 'Document',
}

export const TemplateItem = ({ handleClick, template }: Props) => {
  return (
    <Item
      onClick={template.uploaded_document ? handleClick : undefined}
      use={template.uploaded_document ? 'button' : undefined}
    >
      <Item.Avatar>
        <Avatar useIcon={typeToIcon[template.document_template.template_type.id]}>
          {template.uploaded_document && (
            <Avatar.Badge
              bg={documentStatusToColor[template.uploaded_document.status.id]}
              useIcon={documentStatusToIcon[template.uploaded_document.status.id]}
            />
          )}
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{template.name}</Item.Title>
        {template.uploaded_document && (
          <Item.Description>
            <Text color={documentStatusToColor[template.uploaded_document.status.id]}>
              {`${template.uploaded_document.status.name} `}
            </Text>
            <Text>{formatDateTime(template.update_date_time)}</Text>
          </Item.Description>
        )}
      </Item.Content>
    </Item>
  )
}
