import React, { useState } from 'react'
import { Box, MoreBar, Text, TextArea } from '@revolut/ui-kit'
import { updateEmployeeStatus as updateEmployeeStatusApi } from '@src/api/employees'
import {
  cancelOnboarding,
  startOnboarding,
  transferOnboarding,
} from '@src/api/onboardingEmployeesV2'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import {
  EmployeeInterface,
  EmployeeOptionInterface,
  EmployeeStatusTransitionsInterface,
  IdStatuses,
} from '@src/interfaces/employees'
import { OnboardingTimelineInterface } from '@src/interfaces/onboardingV2'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import SideBar from '@src/components/SideBar/SideBar'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import SettingsButtons from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'

export type OpenedSidebar = 'confirm-date' | 'transfer-ownership' | null

interface OnboardingActionsProps {
  employee: EmployeeInterface
  onboardingData?: OnboardingTimelineInterface
}

export const OnboardingActions = ({
  employee,
  onboardingData,
}: OnboardingActionsProps) => {
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false)
  const [cancelPending, setCancelPending] = useState(false)
  const [cancelReason, setCancelReason] = useState<string>()
  const [confirmOwnershipPending, setConfirmOwnershipPending] = useState(false)
  const [markAsNotHiredPending, setMarkAsNotHiredPending] = useState(false)
  const [newHrManager, setNewHrManager] = useState<EmployeeOptionInterface>()
  const [openedSidebar, setOpenedSidebar] = useState<OpenedSidebar>(null)
  const [startPopupOpen, setStartPopupOpen] = useState(false)
  const [satrtPending, setSatrtPending] = useState(false)

  const showMarkAsNotHired =
    employee.status.id === IdStatuses.onboarding ||
    employee.status.id === IdStatuses.pending ||
    employee.status.id === IdStatuses.hired

  if (!onboardingData) {
    return null
  }

  const onStartProcess = () => {
    setSatrtPending(true)

    // TODO update onb state
    startOnboarding(onboardingData.id).finally(() => {
      setStartPopupOpen(false)
      setSatrtPending(false)
    })
  }

  const onCancelProcess = () => {
    setCancelPending(true)

    // TODO update onb state
    cancelOnboarding(onboardingData.id, cancelReason).finally(() => {
      setCancelPopupOpen(false)
      setCancelPending(false)
    })
  }

  const onConfirmTransferOwnership = async () => {
    if (!newHrManager) {
      return
    }
    setConfirmOwnershipPending(true)
    try {
      await transferOnboarding(onboardingData.id, newHrManager)
    } finally {
      setOpenedSidebar(null)
      setConfirmOwnershipPending(false)
    }
  }

  const onMarkAsNotHired = () => {
    setMarkAsNotHiredPending(true)

    updateEmployeeStatusApi(employee.id, {
      status: { id: IdStatuses.not_hired },
    } as EmployeeStatusTransitionsInterface).finally(() => {
      setMarkAsNotHiredPending(false)
    })
  }

  return (
    <>
      <Box>
        <SettingsButtons pb="s-16" maxCount={3}>
          {onboardingData?.status.id === 'not_started' && (
            <MoreBar.Action onClick={() => setStartPopupOpen(true)} variant="accent">
              Begin onboarding
            </MoreBar.Action>
          )}
          <MoreBar.Action
            to={pathToUrl(ROUTES.FORMS.SEND_EMPLOYEE_EMAIL.EMPLOYEE, {
              employeeId: employee.id,
            })}
            use={InternalLink}
            useIcon="Envelope"
          >
            Send email
          </MoreBar.Action>
          <MoreBar.Action
            onClick={() => setOpenedSidebar('transfer-ownership')}
            useIcon="ArrowRightLeft"
          >
            Transfer ownership
          </MoreBar.Action>
          {/* <MoreBar.Action
            onClick={() => setOpenedSidebar('confirm-date')}
            variant="negative"
            useIcon="Time"
          >
            Delay start date
          </MoreBar.Action> */}
          {showMarkAsNotHired && (
            <MoreBar.Action
              onClick={onMarkAsNotHired}
              pending={markAsNotHiredPending}
              useIcon="CrossVoid"
            >
              Mark as not hired
            </MoreBar.Action>
          )}
          {onboardingData?.status.id !== 'cancelled' && (
            <MoreBar.Action
              onClick={() => setCancelPopupOpen(true)}
              variant="negative"
              useIcon="CrossCircle"
            >
              Cancel process
            </MoreBar.Action>
          )}
        </SettingsButtons>
      </Box>

      <SideBar
        isOpen={openedSidebar === 'transfer-ownership'}
        onClose={() => setOpenedSidebar(null)}
        title="Transfer ownership"
        variant="wide"
      >
        <MoreBar>
          <MoreBar.Action
            disabled={newHrManager?.id === onboardingData?.hr_manager.id}
            onClick={onConfirmTransferOwnership}
            pending={confirmOwnershipPending}
            variant="accent"
            useIcon="Check"
          >
            Confirm
          </MoreBar.Action>
        </MoreBar>

        <Box mt="s-16">
          <RadioSelectInput
            label="HR manager"
            selector={selectorKeys.employee}
            onChange={option => {
              if (option) {
                setNewHrManager(option)
              }
            }}
            value={newHrManager}
          />
        </Box>
      </SideBar>

      <ConfirmationDialog
        onClose={() => setStartPopupOpen(false)}
        onConfirm={onStartProcess}
        onReject={() => setStartPopupOpen(false)}
        open={startPopupOpen}
        loading={satrtPending}
        label="Send onboarding invitation"
        body={`${employee.full_name} will receive a invitation email to start his onboarding via the platform.`}
        yesMessage="Send invitation"
        noMessage="Cancel"
      />

      <ConfirmationDialog
        onClose={() => setCancelPopupOpen(false)}
        onConfirm={onCancelProcess}
        onReject={() => setCancelPopupOpen(false)}
        open={cancelPopupOpen}
        loading={cancelPending}
        body={
          <>
            <Text mb="s-8">Are you sure you want to cancel this onboarding process?</Text>
            <TextArea
              label="Cancel reason"
              onChange={e => setCancelReason(e.currentTarget.value)}
              rows={3}
              value={cancelReason}
            />
          </>
        }
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}
