import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { DataHandlerInterface } from '@components/FormPreview/FormPreview'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { EmployeeInformation } from './components/EmployeeInformation'
import { OnboardingInformation } from './components/OnboardingInformation'

type Props = {
  data: EmployeeInterface
  primaryDataHandler: DataHandlerInterface<EmployeeInterface>
  sectionsData: ProfileSectionsData
}

export const SummaryTab = ({ data, primaryDataHandler, sectionsData }: Props) => {
  return (
    <TwoColumnsLayout
      left={<OnboardingInformation />}
      right={
        <EmployeeInformation
          data={data}
          primaryDataHandler={primaryDataHandler}
          sectionsData={sectionsData}
        />
      }
    />
  )
}
